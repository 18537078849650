<template>
    <div class="o-AppoNavbar">
        <DlButtonNav
          :class="'mx-2 mx-md-3 mx-lg-5'"
          icon="bi bi-telephone-inbound"
          href="/richiamata"
          label="Prenota una richiamata"
          type="link"
          v-if="hasRecallService"
        />

        <DlButtonNav
          :class="'mx-2 mx-md-3 mx-lg-5'"
          icon="icon-appo-booking"
          href="/prenota"
          label="Prenota online"
          type="link"
          v-if="hasBookingOnline"
        />

        <DlButtonNav
          :class="'mx-2 mx-md-3 mx-lg-5'"
          icon="icon-appo-stethoscope"
          href="/prenotazioni"
          label="Le mie prenotazioni"
          type="link"
        />

        <DlButtonNav
          :class="'mx-2 mx-md-3 mx-lg-5'"
          icon="bi bi-box-arrow-down"
          href="/verifica"
          label="Scarica referti"
          type="link"
          v-if="hasMedicalReports"
        />

        <DlButtonNav v-for="(service, key) in getServices" :key="key"
          :class="'mx-2 mx-md-3 mx-lg-5'"
          :icon="service.icon"
          :href="service.href"
          :label="service.label"
          :target="service.target"
          type="external"
        />
    </div>
</template>

<script>
import DlButtonNav from '@/components/atoms/dl-buttonnav/dl-buttonnav.vue';

export default {
  name: 'DlNavbar',
  components: {
    DlButtonNav,
  },
  computed: {
    hasMedicalReports() {
      return this.$store.getters['settings/get'].medical_report;
    },
    hasRecallService() {
      return this.$store.getters['organizations/hasCupTelefonico'];
    },
    getServices() {
      return this.$store.getters['settings/get'].services;
    },
    hasBookingOnline() {
      return this.$store.getters['settings/hasBookingOnline'];
    },
  }
};
</script>

<style lang="scss">
  @import 'dl-navbar.scss';
</style>
